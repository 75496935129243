import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import toUpper from 'lodash/toUpper';
import { external } from '@emobg/web-api-client';
import { COUNTRIES_ISO_CODES, DATE_FORMAT, removeBase64Header } from '@emobg/web-utils';

import languageService from '@/services/Language';
import { pickUtmParameters } from '@/utils/utms';
import { ORIGIN_PLATFORM } from '@/constants/app';
import { SERVICES } from '@/constants/services.const';
import externalApi from '@/vue/api/external/app.external-api';
import { createError, generateMarketingPayload } from '@/helpers/actionsHelpers';

import { MUTATIONS } from './mutations';

export const ACTIONS = {
  setCountries: 'setCountries',
  setPreRegister: 'setPreRegister',
  setLegalDocuments: 'setLegalDocuments',
  linkUserCompany: 'linkUserCompany',
  createCompany: 'createCompany',
  checkEmail: 'checkEmail',
};

export default {
  async [ACTIONS.setCountries]({ commit }) {
    const locale = languageService.getLanguage();
    return external.app.getCountriesByService({
      service: SERVICES.carSharing,
      language: languageService.getLanguageCodeFromLocale(locale),
    }).then(countries => {
      commit(MUTATIONS.countries, countries);
    }).catch(() => {
      commit(MUTATIONS.countries, []);
    });
  },
  [ACTIONS.setPreRegister]({ commit, state }) {
    const locale = languageService.getLanguage();
    const language = languageService.getLanguageCodeFromLocale(locale);
    return external.userCompanyAccount.getCompanyPreRegister({
      countryCode: state.data.serviceCountryCode,
      fiscalNumber: state.data.vatNumber,
      serviceCountryCode: state.data.serviceCountryCode,
      language,
    }).then(response => {
      commit(MUTATIONS.extraFields, response.extraFields);
      commit(MUTATIONS.legalForms, response.legalForms);
      commit(MUTATIONS.sectorEntities, response.sectorOfEntities);
    });
  },
  async [ACTIONS.checkEmail]({ state, commit }) {
    return external.userCompanyAccount.getCheckCompanyEmail({ email: state.data.companyEmail }).then(() => {
      commit(MUTATIONS.setIsEmailValid, true);
    }).catch(() => {
      commit(MUTATIONS.setIsEmailValid, false);
    });
  },
  async [ACTIONS.setLegalDocuments]({ state, commit }, locale) {
    try {
      const csOperatorUuid = get(state.info, 'csOperator');
      const defaultLanguage = languageService.getLanguageCodeFromLocale(languageService.defaultLang);
      const language = languageService.getLanguageCodeFromLocale(locale) || defaultLanguage;

      const { documents = [] } = (await external.userCompanyCSOperator.getLegalDocuments(csOperatorUuid, 'v4', language) || {});

      const documentsUuids = map(documents, 'uuid');
      commit(MUTATIONS.legalDocumentsAccepted, documentsUuids);
      commit(MUTATIONS.legalDocuments, documents);
    } catch (error) {
      createError(state.notifyErrorCallback, error);
    }
  },
  [ACTIONS.linkUserCompany]({ state }, { locale }) {
    const { data } = state;

    let fiscalNumber = data.fiscal_number;
    if (localStorage.getItem('env_test')) {
      fiscalNumber = Math.floor(Math.random() * 999999999) + 1;
    }

    const params = {
      commercial_name: data.companyName,
      fiscal_name: data.companyName,
      address: data.companyAddress.address,
      address_details: data.extraCompanyAddress,
      country_code: data.country_code,
      fiscal_number: fiscalNumber,
      phone_1: data.workPhoneNumber,
      size: data.companySize.value,
      legal_form: data.legalForm,
      legal_document_uuids: data.legalDocumentsAccepted,
      sector_of_entity: data.sectorEntity.internal_name,
      email: data.workEmail,
      language: locale,
    };

    return externalApi.linkExistingUser(params, state.linkAccount.idpUuid);
  },
  async [ACTIONS.createCompany]({ state }, payload) {
    const { data } = state;

    const marketingPayload = generateMarketingPayload(get(payload, 'subscriptions'), data.marketingTerms);
    const utmParameters = pickUtmParameters(get(payload, 'queryString', {}));
    const isItalianOperator = toUpper(data.serviceCountryCode) === COUNTRIES_ISO_CODES.italy;
    const showItalianID = isItalianOperator && get(data, 'extraDocuments.italianIdFront') && get(data, 'extraDocuments.italianIdBack');
    const showItalianPassport = isItalianOperator && get(data, 'extraDocuments.italianPassport');
    const params = {
      address: data.companyAddress.address,
      address_details: data.extraCompanyAddress,
      commercial_name: data.companyName,
      email: data.companyEmail,
      fiscal_name: data.companyName,
      fiscal_number: get(data, 'vatNumber'),
      first_name: data.firstName,
      last_name: data.lastName,
      legal_form: data.legalForm,
      password: data.password,
      phone_1: data.phoneNumber,
      country_code: data.serviceCountryCode,
      service_country_code: data.serviceCountryCode,
      size: data.companySize,
      sector_of_entity: data.sectorEntity,
      title: data.title,
      legal_document_uuids: data.legalDocumentsAccepted,
      extra_documents: {
        ...isItalianOperator && {
          certificato_di_inscrizione_cc: removeBase64Header(data.extraDocuments.italianCertificate),
          codice_fiscale: data.italianFiscalCode,
        },
        ...showItalianID && {
          italian_id_front: removeBase64Header(data.extraDocuments.italianIdFront),
          italian_id_back: removeBase64Header(data.extraDocuments.italianIdBack),
        },
        ...showItalianPassport && {
          italian_passport: removeBase64Header(data.extraDocuments.italianPassport),
        },
      },
      birthdate: moment(data.birthDate).format(DATE_FORMAT.date),
      ...marketingPayload,
      marketing_campaign: size(utmParameters) ? utmParameters : undefined,
      origin: ORIGIN_PLATFORM,
      siret_number: data.siretNumber,
    };
    return external.userCompanyAccount.createCompany(params, 'v2');
  },
};
